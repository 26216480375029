import React from 'react'

import { Box, Heading3, Grid, Col, Space } from '@gousto-internal/citrus-react'

import { SearchCategory, SearchCategoryProps } from '../SearchCategory/SearchCategory'

type SearchCategoriesGridProps = {
  categories: SearchCategoryProps[]
  title: string
}

export const SearchCategoriesGrid = ({ categories, title }: SearchCategoriesGridProps) => (
  <Box>
    <Heading3 size={[4, null, 5]}>{title}</Heading3>
    <Space size={4} />
    <Grid gap={[3, null, 4]}>
      {categories.map(({ id, imageUrl, name, recipeCount }) => (
        <Col size={[6, null, 4]} key={id}>
          <SearchCategory id={id} imageUrl={imageUrl} name={name} recipeCount={recipeCount} />
        </Col>
      ))}
    </Grid>
  </Box>
)
