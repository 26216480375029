const CATEGORIES_IMAGES = [
  {
    id: '97270056-cd65-11e8-a2d2-067a72dd5dba',
    imageUrl: 'https://production-media.gousto.co.uk/collection-images/For-You-Foreground-v3.png',
  },
  {
    id: 'ca8f71be-63ac-11e6-a693-068306404bab',
    imageUrl:
      'https://production-media.gousto.co.uk/collection-images/All-Recipes-Foreground-v3.png',
  },
  {
    id: '56b88b66-7e0b-11ec-a129-02e1cea636b3',
    imageUrl:
      'https://production-media.gousto.co.uk/collection-images/NewLimited-Foreground-v3.png',
  },
  {
    id: 'b6606d9e-2dbb-11ed-a194-022218cc141f',
    imageUrl: 'https://production-media.gousto.co.uk/collection-images/QuickEasy-Foreground-v3.png',
  },
  {
    id: '6c5806f2-61b5-11ed-a541-027900d93479',
    imageUrl:
      'https://production-media.gousto.co.uk/collection-images/SaveSavour-Foreground-v3.png',
  },
  {
    id: '6067fb3e-afbe-11e8-8ee6-0645394f11ea',
    imageUrl:
      'https://production-media.gousto.co.uk/collection-images/HealthyChoices-Foreground-v3.png',
  },
  {
    id: 'e55383da-336d-11e9-b24d-06399ef4685e',
    imageUrl:
      'https://production-media.gousto.co.uk/collection-images/Everyday-Favourites-foreground.png',
  },
  {
    id: 'bc5fc11e-afbf-11e8-9691-0645394f11ea',
    imageUrl: 'https://production-media.gousto.co.uk/collection-images/10MM-Foreground-v3.png',
  },
  {
    id: '3f6fa670-139e-11eb-98fd-02dd38895341',
    imageUrl: 'https://production-media.gousto.co.uk/collection-images/Pin5-Foreground-v3.png',
  },
  {
    id: 'a4f1eb8e-2eda-11e9-81ec-06399ef4685e',
    imageUrl: 'https://production-media.gousto.co.uk/collection-images/Chicken-Foreground-v3.png',
  },
  {
    id: '2fef860c-2ee4-11e9-a326-06399ef4685e',
    imageUrl: 'https://production-media.gousto.co.uk/collection-images/BeefPork-Foreground-v3.png',
  },
  {
    id: 'c70e3ea2-2ee4-11e9-9a17-020aadf6c680',
    imageUrl: 'https://production-media.gousto.co.uk/collection-images/Fish-Foreground-v3.png',
  },
  {
    id: '5c117c20-8b05-11e6-8538-065f01f5b2df',
    imageUrl:
      'https://production-media.gousto.co.uk/collection-images/Vegetarian-Foreground-v3.png',
  },
  {
    id: '77d1eb54-e3e5-11e7-bf51-06543e25a81c',
    imageUrl:
      'https://production-media.gousto.co.uk/collection-images/PlantBased-Foreground-v3.png',
  },
  {
    id: 'cfb814b4-9c79-11e7-9975-06bf888d1530',
    imageUrl: 'https://production-media.gousto.co.uk/collection-images/DairyFree-Foreground-v3.png',
  },
  {
    id: '771f5b88-6da5-11ee-9749-024f3eab9401',
    imageUrl:
      'https://production-media.gousto.co.uk/collection-images/FamilyFavourites-Foreground-v3.png',
  },
  {
    id: '84e4eed8-c9d2-4c83-ad03-bc86172957ac',
    imageUrl:
      'https://production-media.gousto.co.uk/collection-images/GlobalCuisine-Foreground-v3.png',
  },
  {
    id: '1b24de1c-3473-11ef-8044-06a0257ab9ff',
    imageUrl:
      'https://production-media.gousto.co.uk/collection-images/HealthyChoices-Foreground-v3.png',
  },
]

export const getCategoryImageUrl = (categoryId: string) =>
  CATEGORIES_IMAGES.find((category) => category.id === categoryId)?.imageUrl ||
  'https://production-media.gousto.co.uk/collection-images/Placeholder-Foreground-v3.png'
