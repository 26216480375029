import { useEffect, useRef, useState } from 'react'

import { menuSearchFetcher } from '@library/menu-service'
import { MenuSearch } from '@library/menu-service/queries/menuApiMenuSearch/types'

type MenuSearchRequest = {
  deliveryDate: string
  menuId: string
  searchText: string
}

type MenuSearchResponse = {
  data: MenuSearch | null
  error: unknown
  isLoading: boolean
}

const initialResponse = {
  data: null,
  error: null,
  isLoading: false,
}

export const useMenuSearch = ({ deliveryDate, menuId, searchText }: MenuSearchRequest) => {
  const [response, setResponse] = useState<MenuSearchResponse>(initialResponse)
  const previousResponseData = useRef<MenuSearch | null>(null)

  useEffect(() => {
    const fetchMenuSearch = async () => {
      if (!searchText) {
        previousResponseData.current = null
        setResponse(initialResponse)

        return
      }

      try {
        setResponse({ data: previousResponseData.current, error: null, isLoading: true })

        const data = await menuSearchFetcher({
          deliveryDate,
          menuId,
          numPortions: 2,
          search: searchText,
        })

        previousResponseData.current = data
        setResponse({ data, error: null, isLoading: false })
      } catch (error) {
        previousResponseData.current = null
        setResponse({ data: null, error, isLoading: false })
      }
    }
    fetchMenuSearch()
  }, [deliveryDate, menuId, searchText])

  return response
}
