import React, { useState } from 'react'

import {
  Box,
  Heading3,
  Modal,
  ModalBody,
  useModal,
  ModalClose,
  ModalHeader,
} from '@gousto-internal/citrus-react'
import { useSelector } from 'react-redux'

import { getBasketDate, getBasketMenuUuid } from 'selectors/basket'

import { PopularCategories } from '../PopularCategories/PopularCategories'
import { SearchCategoriesGrid } from '../SearchCategoriesGrid/SearchCategoriesGrid'
import { SearchInput } from '../SearchInput'
import { useMenuSearch } from '../useMenuSearch'
import { getCategoryImageUrl } from '../utils'

export const recipeSearchModalName = 'RecipeSearchModal'

export const RecipeSearchModal = () => {
  const { closeCurrentModal } = useModal()
  const [searchText, setSearchText] = useState('')
  const basketDate = useSelector(getBasketDate)
  const basketMenuUuid = useSelector(getBasketMenuUuid)

  const { data, error, isLoading } = useMenuSearch({
    deliveryDate: basketDate,
    menuId: basketMenuUuid,
    searchText,
  })

  return (
    <Modal
      name={recipeSearchModalName}
      preventScroll
      onRequestClose={() => {
        closeCurrentModal()
      }}
    >
      <ModalHeader>
        <Box paddingRight={12}>
          <SearchInput onTextChange={setSearchText} />
        </Box>
        <ModalClose onClose={() => closeCurrentModal()} />
      </ModalHeader>
      <ModalBody>
        <Box minHeight="100vh" paddingH={2} paddingBottom={4}>
          {(() => {
            if (error) {
              // TODO: REPLACE WITH ACTUAL ERROR COMPONENT
              return <Box>Error: something went bad</Box>
            } else if (data) {
              const { categories, recipes } = data

              return (
                <Box>
                  {recipes.length > 0 && (
                    // TODO: REPLACE WITH ACTUAL SEARCH RECIPE TILES
                    <Box paddingBottom={6}>
                      <Heading3 size={[4, null, 5]}>Search results</Heading3>
                      {recipes.map((recipe) => (
                        <Box key={recipe.id} paddingBottom={6}>
                          <img src={recipe.images[0].crops[0].url} alt={recipe.name} />
                          {recipe.name}
                        </Box>
                      ))}
                    </Box>
                  )}
                  {categories.length > 0 && (
                    <SearchCategoriesGrid
                      categories={categories.map((category) => ({
                        id: category.id,
                        imageUrl: getCategoryImageUrl(category.id),
                        name: category.short_title,
                        recipeCount: category.recipe_count,
                      }))}
                      title="Related Categories"
                    />
                  )}
                </Box>
              )
            } else if (isLoading) {
              // TODO: REPLACE WITH ACTUAL LOADING SKELETON
              return <Box>Loading...</Box>
            } else {
              return <PopularCategories />
            }
          })()}
        </Box>
      </ModalBody>
    </Modal>
  )
}
