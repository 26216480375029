import React from 'react'

import { useCollections } from 'routes/Menu/domains/collections'

import { SearchCategoriesGrid } from '../SearchCategoriesGrid/SearchCategoriesGrid'
import { SearchCategoryProps } from '../SearchCategory/SearchCategory'
import { getCategoryImageUrl } from '../utils'

export const POPULAR_CATEGORIES_IDS = [
  '56b88b66-7e0b-11ec-a129-02e1cea636b3', // New & Limited
  'b6606d9e-2dbb-11ed-a194-022218cc141f', // Quick & Easy
  '1b24de1c-3473-11ef-8044-06a0257ab9ff', // Healthy Choices
  'e55383da-336d-11e9-b24d-06399ef4685e', // Everyday Favourites
  '5c117c20-8b05-11e6-8538-065f01f5b2df', // Vegetarian
  '6c5806f2-61b5-11ed-a541-027900d93479', // Save & Savour
]

export const PopularCategories = () => {
  const { getCollectionById } = useCollections()

  const getPopularCategories = () =>
    POPULAR_CATEGORIES_IDS.reduce((acc, categoryId) => {
      const category = getCollectionById(categoryId, { visibleOnly: false })
      const recipeCount = category?.get('recipesInCollection')?.size

      if (!category || recipeCount === 0) {
        return acc
      }

      return [
        ...acc,
        {
          id: category.get('id'),
          imageUrl: getCategoryImageUrl(category.get('id')),
          name: category.get('shortTitle'),
          recipeCount,
        },
      ]
    }, [] as SearchCategoryProps[])

  return <SearchCategoriesGrid categories={getPopularCategories()} title="Popular Categories" />
}
