import React, { useState } from 'react'

import {
  AlignItems,
  Box,
  Color,
  Display,
  Icon,
  IconVariant,
  JustifyContent,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'
import { useDebounce } from 'react-use'

const onSpaceOrEnter =
  (callback: (event: React.KeyboardEvent) => void) => (event: React.KeyboardEvent) => {
    if (event.code === 'Space' || event.code === 'Enter') {
      event.preventDefault()
      callback(event)
    }
  }

const CustomInput = withCitrusTheme('input', (theme) => ({
  backgroundColor: theme.color(Color.NeutralGrey_50),
  border: 'none',
  height: '100%',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  width: '100%',

  '&:disabled, &:disabled:hover, &:disabled:focus, &:focus': {
    outline: 'none',
  },

  '&::placeholder': {
    color: theme.color(Color.ColdGrey_600),
    opacity: 1,
  },
}))

type SearchInputProps = {
  onTextChange: (value: string) => void
}

export const SearchInput = ({ onTextChange }: SearchInputProps) => {
  const [text, setText] = useState('')

  useDebounce(() => onTextChange(text), 500, [text])

  const clearText = () => setText('')

  return (
    <Box
      alignItems={AlignItems.Center}
      bg={Color.NeutralGrey_50}
      borderRadius={1.5}
      data-testid="search-input"
      display={Display.Flex}
      height="40px"
      justifyContent={JustifyContent.SpaceBetween}
      paddingH={4}
    >
      <Icon name="search" size={6} />
      <CustomInput
        aria-label="Search the menu"
        onChange={(e) => setText(e.target.value)}
        placeholder="Search the menu"
        type="text"
        value={text}
      />
      {text && (
        <Icon
          name="close"
          onClick={() => clearText()}
          onKeyDown={onSpaceOrEnter(() => clearText())}
          role="button"
          size={6}
          tabIndex={0}
          variant={IconVariant.Default}
        >
          Clear search text
        </Icon>
      )}
    </Box>
  )
}
